<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="PaymentsOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <PaymentsListingNew></PaymentsListingNew>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import ExportTable from "../../../components/Appic/ExportTable";
    // import PaymentsListing from "Components/Appic/PaymentsListing";
    import LoadingComponent from "Components/Appic/LoadingComponent";
    import {mapFields} from "vuex-map-fields";

    const ExportTable = () => import("Components/Appic/ExportTable");
    const PaymentsListingNew = () => ({
        component: import("../../../components/Appic/PaymentsListingNew"),
        loading: LoadingComponent
    });

    export default {
        name: "PaymentsOverview",
        components: {ExportTable, PaymentsListingNew},
        title: '',
        data() {
            return {
                loader: false,
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        methods: {
            newPayment () {
                let tab = window.open('/v1/payments/add','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        mounted(){
            this.$title = this.$t('message.titles.payments')
        }
    }
</script>

<style>
    .v-data-table td {
        padding: 0 5px;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>